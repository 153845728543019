import { ReactElement, useMemo } from 'react';

import { InstagramHeader } from './InstagramHeader';
import { InstagramItemsEmbed } from './InstagramItemsEmbed';

import { IProps } from './types';
import * as S from './styles';

export function SliceInstagramPosts({
  slice: { primary, fields },
}: IProps): ReactElement {
  const postUrls = useMemo(
    () => fields?.map((f) => f?.instagram_link?.url),
    [fields?.map]
  );

  return (
    <S.Wrapper className="instagram-container">
      <S.Padding>
        <InstagramHeader {...primary} />
        <InstagramItemsEmbed postUrls={postUrls} />
      </S.Padding>
    </S.Wrapper>
  );
}

SliceInstagramPosts.componentName = 'instagram_posts';
