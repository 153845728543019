import { media, styled } from '@styles';
import { css } from 'styled-components';
import { Container as DefaultContainer } from '@components/templates/Container';
import { pageTitle } from '@styles/utils';

import chevronLeftImage from 'images/chevron_left.svg';
import chevronRightImage from 'images/chevron_right.svg';

const slickArrow = css`
  .slick-arrow {
    position: absolute;
    width: 56px;
    height: 56px;
    text-indent: -999999px;
    top: 50%;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    z-index: 1;
    ${media.largeScreen`
      background-size: contain !important;
      width: 42px;
      height: 42px;
    `}
    ${media.smallScreen`
      top: 46%;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      transform: translate3d(0, 0, 0);
      box-shadow: 0px 0px 10px 0px #dfdfdf;
    `}
    &:before {
      content: none;
    }
  }
`;

const slickPrev = css`
  .slick-prev {
    background: url(${chevronLeftImage}) no-repeat center !important;
    left: 55px;
    ${media.mediumScreen`
      left: 45px;
    `}
    ${media.smallScreen`
      left: 10px;
    `}
    &.slick-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;

const slickNext = css`
  .slick-next {
    background: url(${chevronRightImage}) no-repeat center !important;
    right: 55px;
    ${media.mediumScreen`
      right: 45px;
    `}
    ${media.smallScreen`
      right: 10px;
    `}
    &.slick-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;

const slickTrack = css`
  .slick-track {
    display: flex;
    left: -20%;
    ${media.mediumScreen`
      left: -10%;
    `}
    ${media.smallScreen`
      left: 0;
    `}
  }
`;

const slickSlide = css`
  .slick-slide {
    display: flex;
    height: auto;
    & > div {
      width: 100%;
    }
  }
`;

const slickList = css`
  .slick-list {
    overflow: hidden;
    width: 100%;
    height: auto !important;
    ${media.mediumScreen`
      width: 100%;
      padding: 0;
    `}
    ${media.smallScreen`
      overflow: visible;
      padding: 0 0px 0 52px !important;
    `}
  }
`;

export const Wrapper = styled.div`
  background-color: hsl(210, 12%, 97%);
  overflow: hidden;
  ${slickList}
  ${slickTrack}
  ${slickSlide}
  ${slickArrow}
  ${slickPrev}
  ${slickNext}
`;

export const Container = styled(DefaultContainer)`
  padding: 2em 2em;
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.smallScreen`
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  `}
`;

export const Title = styled.h2`
  ${pageTitle}
`;

export const Content = styled.div`
  margin-bottom: 2rem;
`;

export const Slider = styled.div`
  // margin: 0;
  position: relative;
`;

export const Slide = styled.div`
  padding-right: 35px;
  height: 100%;
  width: 100%;
  outline: none;
  ${media.largeScreen`
    padding-right: 20px;
  `}
  ${media.smallScreen`
    padding-right: 8px;
    height: auto;
  `}
`;

export const Link = styled.a`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.baseFontSize};
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: ${(props) => props.theme.colors.baseFont};
  ${media.largeScreen`
    letter-spacing: -0.5px;
  `}

  &:after {
    content: '';
    display: block;
    height: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.red};
  }
`;
