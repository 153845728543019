import { PrismicRichText } from '@prismicio/react';
import { ReactElement } from 'react';

import { IHeaderProps } from './types';
import * as S from './styles';

export function InstagramHeader({
  htitle,
  user_name,
  text,
}: IHeaderProps): ReactElement {
  return (
    <S.Header className="instagram-header">
      <S.HeaderTitle className="instagram-header-title">
        <PrismicRichText field={htitle} />
      </S.HeaderTitle>
      <S.HeaderLink className="instagram-header-link">
        <PrismicRichText field={user_name} />
      </S.HeaderLink>
      <S.HeaderHint className="instagram-header-hint">
        <PrismicRichText field={text} />
      </S.HeaderHint>
    </S.Header>
  );
}
