import { useRef, useState } from 'react';
import Link from 'next-translate-routes/link';
import Slider, { Settings } from 'react-slick';
import { useIntl } from 'react-intl';

import { paths } from '@temp/paths';

import { smallScreen } from '@styles/constants';
import { ReviewItem } from '@components/molecules/ReviewItem';
import { ImageGallery } from '@components/organisms/ImageGallery';
import { Container } from '@components/templates/Container';

// import { structuredDataReview } from '../../../../core/SEO/Product/structuredData';

import * as S from './styles';
import { IProps } from './types';

export const ProductReviews: React.FC<IProps> = ({ reviews }) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);

  const showGallery = (gallery, index) => {
    setActiveGallery(gallery);
    setActiveGalleryIndex(index);
    setShow(true);
  };

  const closeGallery = () => {
    setShow(false);
  };

  const sliderSettings: Settings = {
    arrows: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '20px',
    infinite: false,
    responsive: [
      {
        breakpoint: smallScreen,
        settings: {
          adaptiveHeight: true,
          centerPadding: '40px',
          swipe: false,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: true,
  };

  const slider = useRef(null);

  const [startPos, setStartPos] = useState(0);

  const touchStart = (e: React.TouchEvent) => {
    setStartPos(e.nativeEvent.touches[0].clientX);
  };

  const touchEnd = (e: React.TouchEvent) => {
    const endPos = e.nativeEvent.changedTouches[0].clientX;
    const delta = startPos - endPos;
    const minDeltaValue = 25;
    if (Math.abs(delta) > minDeltaValue && delta > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (slider as any).current.slickNext();
    }
    if (Math.abs(delta) > minDeltaValue && delta < 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (slider as any).current.slickPrev();
    }
  };

  if (reviews?.length >= 1) {
    return (
      <S.Wrapper>
        <ImageGallery
          images={activeGallery}
          index={activeGalleryIndex}
          show={show}
          hide={() => {
            closeGallery();
          }}
        />
        <S.Container>
          <S.Header>
            <S.Title>
              {intl.formatMessage({
                defaultMessage: 'What customers are saying',
                id: 'QmxCoW',
              })}
            </S.Title>
            <Link href={paths.reviews} passHref>
              <S.Link>
                {intl.formatMessage({
                  defaultMessage: 'View all reviews',
                  id: 'd1JKui',
                })}
              </S.Link>
            </Link>
          </S.Header>
        </S.Container>
        <S.Content>
          <S.Slider>
            {reviews?.length === 1 ? (
              <Container>
                <ReviewItem item={reviews[0]} showGallery={showGallery} />
              </Container>
            ) : (
              <div
                onTouchStart={(e) => touchStart(e)}
                onTouchEnd={(e) => touchEnd(e)}
              >
                <Slider {...sliderSettings} ref={slider}>
                  {reviews.map((e, i) => (
                    <S.Slide key={`${e.title}-${i}`}>
                      <ReviewItem item={e} showGallery={showGallery} />
                    </S.Slide>
                  ))}
                </Slider>
              </div>
            )}
          </S.Slider>
        </S.Content>
      </S.Wrapper>
    );
  }

  return null;
};
