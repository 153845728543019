import { ReactElement } from 'react';
import Slider, { Settings } from 'react-slick';
import { PrismicRichText } from '@prismicio/react';

import Link from 'next-translate-routes/link';

import { largeScreen, smallScreen } from '@styles/constants';

import { linkResolver } from '@prismic/prismic-config';

import { ISliderProps } from './types';
import * as S from './styles';

export function InstagramItemsSlider({
  items: itemsList,
}: ISliderProps): ReactElement {
  const sliderSettings: Settings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 3,
    // speed: 600,
    variableWidth: true,
    responsive: [
      {
        breakpoint: largeScreen,
        settings: {
          slidesToShow: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: smallScreen,
        settings: {
          slidesToShow: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <S.Slider className="instagram-items-slider">
      <Slider {...sliderSettings} className="instagram-items-slider__slider">
        {itemsList?.map(
          (
            { image, post_title, post_user_name, user_avatar, instagram_link },
            i
          ) => (
            <Link
              href={linkResolver(instagram_link)}
              key={`instagram-link-${i}`}
            >
              <a target="_blank" rel="noreferrer">
                <S.Item className="instagram-items-slider__item" key={i}>
                  <S.ItemImage className="instagram-items-slider__item-image">
                    <img
                      src={image.url}
                      width={360}
                      height={360}
                      loading="lazy"
                      alt="..."
                      style={{ maxWidth: 'auto', height: '100%' }}
                    />
                  </S.ItemImage>
                  <S.ItemInfo className="instagram-items-slider__item-info">
                    {user_avatar && (
                      <S.ItemAvatar className="instagram-items-slider__item-avatar-wr">
                        <img
                          className="instagram-items-slider__item-avatar"
                          src={user_avatar.url}
                          alt="instagram avatar"
                        />
                      </S.ItemAvatar>
                    )}
                    <S.ItemText className="instagram-items-slider__item-text">
                      {post_title && <PrismicRichText field={post_title} />}
                      <S.ItemLink className="instagram-items-slider__item-link">
                        {typeof post_user_name === 'string' ? (
                          <p>{post_user_name}</p>
                        ) : (
                          <PrismicRichText field={post_user_name} />
                        )}
                      </S.ItemLink>
                    </S.ItemText>
                  </S.ItemInfo>
                </S.Item>
              </a>
            </Link>
          )
        )}
      </Slider>
    </S.Slider>
  );
}
