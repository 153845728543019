import { ReactElement, useRef, useState } from 'react';

import Link from 'next-translate-routes/link';
import Slider, { Settings } from 'react-slick';

import { ExternalLinkFragment } from '@prismic/gqlTypes/prismicOperations';
import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import { linkResolver } from '@prismic/prismic-config';

import { smallScreen } from '@styles/constants';

import { SliceFields, IProps } from './types';
import * as S from './styles';

function removeDomainFromUrl(link: ExternalLinkFragment): ExternalLinkFragment {
  const newUrl = new URL(link.url);
  return {
    __typename: link.__typename,
    _linkType: link._linkType,
    url: newUrl.pathname + newUrl.search,
  };
}

const SLYDES_TO_SHOW_DEAFAULT_AMOUNT = 5;

const getSlydesToShowAmount = (fields: SliceFields[] | null): number => {
  if (fields) {
    return fields.length <= SLYDES_TO_SHOW_DEAFAULT_AMOUNT && fields.length >= 2
      ? fields.length - 1
      : SLYDES_TO_SHOW_DEAFAULT_AMOUNT;
  }
  return SLYDES_TO_SHOW_DEAFAULT_AMOUNT;
};

export function SliceInspiration({ slice: { fields } }: IProps): ReactElement {
  const sliderSettings: Settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 0,
    dots: false,
    draggable: false,
    infinite: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: smallScreen,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          speed: 900,
          swipe: false,
          swipeToSlide: true,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: getSlydesToShowAmount(fields),
    speed: 1900,
    variableWidth: true,
  };

  const slider = useRef(null);

  const pauseSlider = () => {
    if (window.innerWidth < smallScreen) {
      return;
    }
    (slider as any).current.slickPause();
  };

  const playSlider = () => {
    if (window.innerWidth < smallScreen) {
      return;
    }
    (slider as any).current.slickPlay();
  };

  const [startPos, setStartPos] = useState(0);

  const touchStart = (e: any) => {
    setStartPos(e.nativeEvent.touches[0].clientX);
  };

  const touchEnd = (e: any) => {
    const endPos = e.nativeEvent.changedTouches[0].clientX;
    const delta = startPos - endPos;
    const minDeltaValue = 25;
    if (Math.abs(delta) > minDeltaValue && delta > 0) {
      (slider as any).current.slickNext();
    }
    if (Math.abs(delta) > minDeltaValue && delta < 0) {
      (slider as any).current.slickPrev();
    }
  };

  return (
    <S.Wrapper
      className="inspiration"
      onMouseEnter={pauseSlider}
      onMouseLeave={playSlider}
      onTouchStart={(e) => touchStart(e)}
      onTouchEnd={(e) => touchEnd(e)}
    >
      <Slider {...sliderSettings} ref={slider}>
        {fields &&
          fields.map((item, i) => {
            if (item.link && item.link.__typename === '_ExternalLink') {
              return (
                <Link
                  href={linkResolver(removeDomainFromUrl(item.link))}
                  key={`inspiration__item_${i}`}
                >
                  <a>
                    <S.Item className="inspiration__item">
                      <PrismicNextImage
                        field={item.image}
                        height={320}
                        style={{ maxWidth: 'auto', height: '100%' }}
                        sizes="(max-width: 540px) 66vw, (max-width: 1279px) 50vw, 20vw"
                      />
                    </S.Item>
                  </a>
                </Link>
              );
            }

            return (
              <S.Item
                className="inspiration__item"
                key={`inspiration__item_${i}`}
              >
                <PrismicNextImage
                  field={item.image}
                  height={320}
                  style={{ maxWidth: 'auto', height: '100%' }}
                />
              </S.Item>
            );
          })}
      </Slider>
    </S.Wrapper>
  );
}

SliceInspiration.componentName = 'inspiration';

export default SliceInspiration;
