import { media, styled } from '@styles';
import { css } from 'styled-components';

import chevronLeftImage from 'images/chevron_left.svg';
import chevronRightImage from 'images/chevron_right.svg';

const slickArrow = css`
  .slick-arrow {
    width: 56px;
    height: 56px;
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: none;
    transform: translate(0, -50%);
    text-indent: -999999px;
    &:before {
      content: none;
    }
    ${media.largeScreen`
      width: 42px;
      height: 42px;
    `}
    ${media.smallScreen`
      width: 33px;
      height: 33px;
      transform: translate3d(0, 0, 0);
    `}
  }
`;

const slickPrev = css`
  .slick-prev {
    background: url(${chevronLeftImage}) no-repeat center !important;
    background-repeat: no-repeat;
    left: 65px;
    ${media.largeScreen`
      background-size: contain !important;
    `}
    ${media.mediumScreen`
      left: 30px;
    `}
    ${media.mediumScreen`
      left: 14px;
    `}
  }
`;

const slickNext = css`
  .slick-next {
    background: url(${chevronRightImage}) no-repeat center !important;
    background-repeat: no-repeat;
    right: 65px;
    ${media.largeScreen`
      background-size: contain !important;
    `}
    ${media.mediumScreen`
      right: 30px;
    `}
    ${media.mediumScreen`
      right: 14px;
    `}
  }
`;

const slickTrack = css`
  .slick-track {
    display: flex !important;
    ${media.mediumScreen`
      align-items: center;
    `}
  }
`;

const slickSlide = css`
  .slick-slide {
    padding: 0 10px;
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  margin: 2em 0;

  img {
    // max-width: 420px;
    height: 320px;
    display: block;
    ${media.largeScreen`
      max-width: 320px;
    `}
    ${media.mediumScreen`
      ax-width: 280px;
    `}
    ${media.smallScreen`
      max-width: 300px;
      max-height: 380px;
    `}
  }

  ${slickTrack}
  ${slickSlide}
  ${slickArrow}
  ${slickPrev}
  ${slickNext}
`;

export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mediumScreen`
    margin-right: 10px;
  `}
`;
