import { media, styled } from '@styles';
import { css } from 'styled-components';
import { Container } from '@components/templates/Container';
import { pageTitle } from '@styles/utils';

import chevronLeftImage from 'images/chevron_left.svg';
import chevronRightImage from 'images/chevron_right.svg';

const slickArrow = css`
  .slick-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.08);
    z-index: 1;
    transform: translate3d(0, 0, 0);
    ${media.largeScreen`
      background-size: contain !important;
      width: 42px;
      height: 42px;
    `}
    ${media.smallScreen`
      height: 33px;
      width: 33px;
    `}
    ${media.xSmallScreen`
      top: 40vw;
    `}
    &:before {
      content: none;
    }
  }
`;

const slickPrev = css`
  .slick-prev {
    background: url(${chevronLeftImage}) no-repeat center !important;
    left: 32px;
    ${media.largeScreen`
      left: 16px;
    `}
    &.slick-disabled {
      opacity: 0.5;
    }
  }
`;

const slickNext = css`
  .slick-next {
    background: url(${chevronRightImage}) no-repeat center !important;
    right: 32px;
    ${media.largeScreen`
      right: 16px;
    `}
    &.slick-disabled {
      opacity: 0.5;
    }
  }
`;

const slickList = css`
  .slick-list {
    margin: 0 -15px;
    ${media.smallScreen`
      margin: 0 -10px;
    `}
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const Padding = styled(Container)`
  padding: 2em 2em;
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const HeaderTitle = styled.div`
  ${pageTitle}
`;

export const HeaderLink = styled.div`
  font-family: ${(props) => props.theme.typography.thinFontFamily};
  font-size: ${(props) => props.theme.typography.h4FontSize};
  margin-bottom: 6px;
  color: ${(props) => props.theme.colors.red};
  line-height: 1.55em;
  * {
    line-height: 1.45em;
  }
`;

export const HeaderHint = styled.div`
  font-family: ${(props) => props.theme.typography.boldFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  line-height: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blueLight};
`;

export const Slider = styled(Container)`
  width: 85vw;
  position: relative;
  max-width: calc(50vw + 680px);
  ${media.largeScreen`
    width: 85vw;
  `}
  ${media.mediumScreen`
    width: 87vw;
  `}
  ${media.smallScreen`
    width: 92vw;
  `}

  .instagram-items-slider__slider {
    position: static;
  }
  ${slickList}
  ${slickArrow}
  ${slickPrev}
  ${slickNext}
`;

export const Item = styled.div`
  width: 80vw !important;
  position: relative;
  max-width: 384px;
  padding: 0 15px;
  ${media.smallScreen`
    padding: 0 10px;
  `}
`;

export const ItemImage = styled.div`
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  padding: 17px;
  background-color: #fff;
  ${media.largeScreen`
    padding: 0 10px;
  `}
`;

export const ItemAvatar = styled.div`
  height: 56px;
  width: 56px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 18px;
  ${media.largeScreen`
    height: 40px;
    width: 40px;
    margin-right: 13px;
  `}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ItemText = styled.div`
  padding-top: 10px;
  font-size: ${(props) => props.theme.typography.baseFontSize};
  color: ${(props) => props.theme.colors.baseFont};
  ${media.largeScreen`
    font-size: 16px;
    padding-top: 0;
  `}
`;

export const ItemLink = styled.span`
  font-size: ${(props) => props.theme.typography.baseFontSize};
  color: ${(props) => props.theme.colors.blueLight};
  display: inline-block;
  ${media.largeScreen`
    margin-right: 10px;
  `}
`;
