/* eslint-disable */
import Image, { ImageProps, ImageLoaderProps } from 'next/future/image';

import * as prismic from '@prismicio/client';

import { smallScreen, mediumScreen } from '@styles/constants';

import { ImgixURLParams } from './types';
import { buildURL } from './buildURL';
import { buildWidthSrcSet } from './buildWidthSrcSet';

const castInt = (input: string | number | undefined): number | undefined => {
  if (typeof input === 'number' || typeof input === 'undefined') {
    return input;
  }
  const parsed = Number.parseInt(input, 10);

  if (Number.isNaN(parsed)) {
    return undefined;
  }
  return parsed;
};

/**
 * Creates a `next/image` loader for Imgix, which Prismic uses, with an optional
 * collection of default Imgix parameters.
 *
 * @see To learn about `next/image` loaders: https://nextjs.org/docs/api-reference/next/image#loader
 * @see To learn about Imgix's URL API: https://docs.imgix.com/apis/rendering
 */
const imgixLoader = (args: ImageLoaderProps): string => {
  const url = new URL(args.src);

  const params: ImgixURLParams = {
    auto: (url.searchParams.getAll('auto') as ImgixURLParams['auto']) || [
      'format',
    ],
    fit: (url.searchParams.get('fit') as ImgixURLParams['fit']) || 'max',
    w: args.width,
    h: undefined,
  };

  if (args.quality) {
    params.q = args.quality;
  }

  return buildURL(args.src, params);
};

export type PrismicNextImageProps = Omit<ImageProps, 'src' | 'alt'> & {
  /**
   * The Prismic Image field or thumbnail to render.
   */
  field: prismic.ImageFieldImage | null | undefined;

  /**
   * An object of Imgix URL API parameters to transform the image.
   *
   * @see https://docs.imgix.com/apis/rendering
   */
  imgixParams?: ImgixURLParams;

  /**
   * Declare an image as decorative by providing `alt=""`.
   *
   * See:
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/alt#decorative_images
   */
  alt?: string;

  /**
   * Declare an image as decorative only if the Image field does not have
   * alternative text by providing `fallbackAlt=""`.
   *
   * See:
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/alt#decorative_images
   */
  fallbackAlt?: string;
};

/**
 * React component that renders an image from a Prismic Image field or one of
 * its thumbnails using `next/image`. It will automatically set the `alt`
 * attribute using the Image field's `alt` property.
 *
 * It uses an Imgix URL-based loader by default. A custom loader can be provided
 * with the `loader` prop. If you would like to use the Next.js Image
 * Optimization API instead, set `loader={undefined}`.
 *
 * @param props - Props for the component.
 *
 * @returns A responsive image component using `next/image` for the given Image
 *   field.
 * @see To learn more about `next/image`, see: https://nextjs.org/docs/api-reference/next/image
 */
export const PrismicNextImage = ({
  field,
  imgixParams = {},
  alt,
  fallbackAlt,
  ...restProps
}: PrismicNextImageProps) => {
  if (typeof alt === 'string' && alt !== '') {
    console.warn(
      `[PrismicNextImage] The "alt" prop can only be used to declare an image as decorative by passing an empty string (alt="") but was provided a non-empty string. You can resolve this warning by removing the "alt" prop or changing it to alt="".`
    );
  }

  if (typeof fallbackAlt === 'string' && fallbackAlt !== '') {
    console.warn(
      `[PrismicNextImage] The "fallbackAlt" prop can only be used to declare an image as decorative by passing an empty string (fallbackAlt="") but was provided a non-empty string. You can resolve this warning by removing the "fallbackAlt" prop or changing it to fallbackAlt="".`
    );
  }

  if (prismic.isFilled.imageThumbnail(field)) {
    const src = buildURL(field.url, imgixParams);
    const url = new URL(src);
    const mediaUrl = new URL(process.env.NEXT_PUBLIC_MEDIA_BUCKET_URL);
    url.hostname = mediaUrl.hostname;

    const ar = field.dimensions.width / field.dimensions.height;

    let resolvedWidth = field.dimensions.width;
    let resolvedHeight = field.dimensions.height;

    if ('width' in restProps || 'height' in restProps) {
      const castedWidth = castInt(restProps.width);
      const castedHeight = castInt(restProps.height);

      if (castedWidth) {
        resolvedWidth = castedWidth;
      } else if (castedHeight) {
        resolvedWidth = ar * castedHeight;
      }

      resolvedHeight = resolvedWidth / ar;
    }

    const mobileSrcSet = field['mobile']
      ? buildWidthSrcSet(field['mobile'].url, {
          widths: [smallScreen, 2 * smallScreen],
        })
      : null;
    const tabletSrcSet = field['tablet']
      ? buildWidthSrcSet(field['tablet'].url, {
          widths: [mediumScreen, 2 * mediumScreen],
        })
      : null;

    return (
      <picture>
        {mobileSrcSet && (
          <source
            media={`(max-width: ${smallScreen}px)`}
            srcSet={mobileSrcSet}
          />
        )}
        {tabletSrcSet && (
          <source
            media={`(max-width: ${mediumScreen}px)`}
            srcSet={tabletSrcSet}
          />
        )}
        <Image
          src={url.href}
          width={resolvedWidth}
          height={resolvedHeight}
          alt={alt ?? (field.alt || fallbackAlt)}
          loader={imgixLoader}
          {...restProps}
        />
      </picture>
    );
  }
  return null;
};
