import { ReactElement, useEffect, useMemo, useState } from 'react';

import { InstagramItemsSlider } from './InstagramItemsSlider';

import { InstagramPost, InstagramEmbedResponse, IItemProps } from './types';

const appId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
const clientToken = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_TOKEN;

const clientAccessToken = `${appId}|${clientToken}`;

const getInstagramEmbed = async (
  url: string
): Promise<InstagramEmbedResponse | void> => {
  try {
    const response = await fetch(
      `https://graph.facebook.com/v14.0/instagram_oembed?url=${url}&fields=thumbnail_url,author_name&access_token=${clientAccessToken}`
    );
    if (response.ok) {
      const data = await response.json();
      return { ...data, url };
    }
  } catch (error) {
    console.error('error: ', error);
  }
};

export function InstagramItemsEmbed({ postUrls }: IItemProps): ReactElement {
  const [embedPosts, setEmbedPosts] = useState<InstagramEmbedResponse[]>([]);

  useEffect(() => {
    if (postUrls) {
      const getInstagramEmbedPosts = async (): Promise<void> => {
        const postsData = await Promise.all(postUrls.map(getInstagramEmbed));
        const filteredPostData: InstagramEmbedResponse[] = [];
        postsData.forEach((post) => {
          if (post) {
            filteredPostData.push(post);
          }
        });

        setEmbedPosts(filteredPostData);
      };
      getInstagramEmbedPosts();
    }
  }, [postUrls]);

  const instagramStaticItems = useMemo(
    () =>
      embedPosts.map(
        (post): InstagramPost => ({
          image: {
            url: post.thumbnail_url,
          },
          instagram_link: { url: post.url },
          post_user_name: `@${post.author_name}`,
        })
      ),
    [embedPosts]
  );

  return <InstagramItemsSlider items={instagramStaticItems} />;
}
