import { media, styled } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #fdfdfe;
  height: 100%;
  max-width: 400px;
  ${media.smallScreen`
    height: auto;
    width: 90vw;
  `}

  &:hover {
    background: #ffffff;
    box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.07);
  }
`;

export const Stars = styled.div`
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: ${(props) => props.theme.typography.h4FontSize};
  font-family: 'Segoe UI';
  line-height: 1;
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      #c34c3e var(--percent),
      #c78881 var(--percent)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Author = styled.div``;

export const AuthorName = styled.div`
  font-size: ${(props) => props.theme.typography.baseFontSize};
`;
export const AuthorDate = styled.div`
  margin-top: 3px;
  color: rgba(131, 144, 152, 0.5);
`;

export const Extra = styled.div`
  margin-top: 1em;
  display: flex;
`;

export const Avatar = styled.div`
  margin-right: 19px;
  max-height: 56px;
  max-width: 56px;
  ${media.largeScreen`
    margin-right: 12px;
    max-height: 46px;
    max-width: 46px;
  `}

  img {
    width: 100%;
  }
`;

export const Description = styled.div`
  font-family: ${(props) => props.theme.typography.extraFontFamily};
  font-size: ${(props) => props.theme.typography.smallFontSize};
  margin-top: 1rem;
`;

export const Title = styled.div`
  margin-top: 1rem;
  font-size: ${(props) => props.theme.typography.h5FontSize};
`;

export const ReadMoreButton = styled.button`
  text-decoration: underline;
  font-size: ${(props) => props.theme.typography.baseFontSize};
`;

export const Rating = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const Header = styled.div``;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ImagesList = styled.div`
  margin: 0px auto;
`;

export const ImageCount = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: #2c2c2c;
  opacity: 0.6;
  color: #ffffff;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  max-height: 480px;
`;

export const ImageGalleryButton = styled.button`
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    max-height: 480px;
  }

  &:hover {
    ${ImageCount} {
      display: flex;
    }
  }
`;
