import { media, styled } from '@styles';

export const Modal = styled.div`
  position: relative;
  background-color: #ffffff;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #808080;
  }
  ${media.mediumScreen`
    height:100%;
    width: 100%;
  `}
`;

export const Content = styled.div`
  padding: calc(${({ theme: { spacing } }) => spacing.gutter} * 2);
  ${media.mediumScreen`
    padding: ${({ theme: { spacing } }) => spacing.gutter};
  `}
`;

export const ProductImage = styled.div`
  height: 366px;
  width: 366px;
  background-color: ${({ theme: { colors } }) => colors.redLight};
  display: flex;
  padding: 62px;
  img {
    mix-blend-mode: darken;
    height: 100%;
    margin: auto;
  }
  ${media.largeScreen`
    height: 270px;
    width: 270px;
    padding: 28px 42px 32px;
  `}
`;

export const Gallery = styled.div`
  display: flex;
  ${media.smallScreen`
    overflow: auto;
  `}
`;

export const ImageCount = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.baseFont};
  opacity: 0.6;
  color: #ffffff;
  border-radius: 6px;
`;

export const ImageButton = styled.button`
  position: relative;

  &:not(:last-child) {
    margin-right: 30px;
    ${media.largeScreen`
      margin-right: 24px;
    `}
  }

  img {
    max-width: 190px;
    max-height: 260px;
    border-radius: 6px;
    ${media.largeScreen`
      max-width: 150px;
      max-height: 200px;
    `}
  }

  &:hover {
    ${ImageCount} {
      display: flex;
    }
  }
`;

export const Category = styled.div`
  margin-top: 20px;
  ${media.largeScreen`
    margin-top: 17px;
    font-size: 14px;
    line-height: 160%;
  `}
`;

export const CategoryTitle = styled.span`
  color: rgba(131, 144, 152, 0.5);
  margin-right: 5px;
`;

export const CategoryName = styled.span`
  text-decoration: underline;
`;

export const Rate = styled.div`
  margin-top: 60px;
  ${media.largeScreen`
    margin-top: 40px;
  `}
`;

export const Title = styled.div`
  margin-top: 17px;
  font-size: ${({ theme }) => theme.typography.h4FontSize};
  line-height: 110%;
  ${media.largeScreen`
    margin-top: 14px;
    line-height: 110%;
  `}
`;

export const Description = styled.div`
  margin-top: 40px;
  font-family: ${({ theme: { typography } }) => typography.extraFontFamily};
  font-size: ${({ theme }) => theme.typography.baseFontSize};
  line-height: 160%;
  max-width: 630px;
  ${media.largeScreen`
    margin-top: 30px;
    max-width: 504px;
  `}
`;

export const Extra = styled.div`
  margin-top: 40px;
  display: flex;
  ${media.largeScreen`
    margin-top: 30px;
  `}
`;

export const CloseButton = styled.button`
  display: block;
  padding: 9px;
  margin: 54px auto 0;
  font-size: ${({ theme }) => theme.typography.h5FontSize};
  line-height: 16px;
  border-bottom: 2px solid #c34c3e;
  cursor: pointer;
  ${media.largeScreen`
    margin: 40px auto 0;
  `}
`;

export const Avatar = styled.div`
  margin-right: 19px;
  max-height: 56px;
  max-width: 56px;
  ${media.largeScreen`
    margin-right: 12px;
    max-height: 46px;
    max-width: 46px;
  `}

  img {
    width: 100%;
  }
`;

export const Author = styled.div``;

export const AuthorName = styled.div`
  font-size: ${({ theme }) => theme.typography.baseFontSize};
  line-height: ${({ theme }) => theme.typography.baseFontSize};
`;

export const AuthorDate = styled.div`
  font-family: ${({ theme: { typography } }) => typography.thinFontFamily};
  margin-top: 3px;
  color: rgba(131, 144, 152, 0.5);
`;
