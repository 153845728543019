import { styled, media } from '@styles';

export const Modal = styled.div``;

export const Header = styled.div<{ divider: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => `1.1rem ${props.theme.spacing.gutter}`};
`;

export const CloseImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.typography.baseFontSize};
  line-height: 1em;
  color: ${(props) => props.theme.colors.baseFontColorOpacityLight};
`;

export const Navigation = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  * {
    color: ${(props) => props.theme.colors.white};
  }

  svg {
    ${media.largeScreen`
      transform: scale(0.8);
    `}
    ${media.smallScreen`
      transform: scale(0.5);
    `}
  }
`;

export const Previous = styled.button`
  ${media.smallScreen`
    left: -20px;
  `}
`;

export const Next = styled.button`
  ${media.smallScreen`
  right: -20px;
`}
`;

export const Preview = styled.div`
  background: none;
  ${media.mediumScreen`
    max-width: 65vw;
  `}
  ${media.smallScreen`
    max-width: initial;
  `}
  img {
    display: block;
    margin: 0 auto;
    max-height: 700px;
    max-width: 100%;
    ${media.largeScreen`
      max-height: 500px;
    `}
  }
`;
