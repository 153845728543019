import { css } from 'styled-components';

import titleTopImage from 'images/title-top.svg';
import titleTopBlueImage from 'images/title-top-blue.svg';
import { media } from './media';

export const paddingLeft = css`
  padding-left: ${({ theme }) => `${theme.container.paddingLeft}px`};
  ${media.mediumScreen`
    padding-left: 25px;
  `}
  ${media.smallScreen`
    padding-left: 0;
  `}
`;

export const pageTitle = css`
  ${({ theme }) => css`
    position: relative;
    font-size: ${(props) => props.theme.typography.h2FontSize};
    font-family: ${(props) => props.theme.typography.thinFontFamily};
    line-height: 1em;
    font-weight: lighter;
    color: ${theme.colors.baseFont};
    letter-spacing: -0.5px;
    padding: 40px 0 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -44px;
      top: 0;
      width: 92px;
      height: 15px;
      background: url(${titleTopImage}) no-repeat left top;
      background-size: contain;
      ${media.largeScreen`
        width: 64px;
        left: -25px;
      `}
      ${media.smallScreen`
        width: 35px;
        left: -15px;
      `}
    }
    &.blue {
      &:before {
        background: url(${titleTopBlueImage}) no-repeat left top;
        background-size: contain;
      }
    }
    ${media.largeScreen`
      padding: 30px 0 0;
    `}
    ${media.smallScreen`
      padding: 15px 0 0;
    `}
    * {
      font: inherit;
    }
  `}
`;

export const prismicRichText = css`
  h1,
  h2,
  h3,
  h4 {
    font-size: ${(props) => props.theme.typography.h3FontSize};
    font-family: ${(props) => props.theme.typography.thinFontFamily};
    line-height: 110%;
    margin-bottom: ${(props) => props.theme.typography.h2FontSize};
    margin-top: ${(props) => props.theme.typography.h2FontSize};
  }

  h5,
  h6 {
    font-size: ${(props) => props.theme.typography.h4FontSize};
    font-family: ${(props) => props.theme.typography.thinFontFamily};
    line-height: 140%;
    margin-bottom: ${(props) => props.theme.typography.h3FontSize};
    margin-top: ${(props) => props.theme.typography.h1FontSize};
  }

  p {
    font-size: ${(props) => props.theme.typography.baseFontSize};
    font-family: ${(props) => props.theme.typography.extraFontFamily};
    color: ${(props) => props.theme.colors.graySemi};
    line-height: 180%;
    margin-bottom: ${(props) => props.theme.typography.h4FontSize};
  }

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.blueLight};
  }

  b {
    font-weight: 600;
  }

  strong {
    color: ${(props) => props.theme.colors.red};
  }

  i {
    font-style: italic;
  }

  & em {
    color: ${(props) => props.theme.colors.red};
  }

  ul,
  ol {
    margin-bottom: ${(props) => props.theme.typography.h4FontSize};

    li {
      font-size: ${(props) => props.theme.typography.baseFontSize};
      font-family: ${(props) => props.theme.typography.extraFontFamily};
      line-height: 220%;
      color: ${(props) => props.theme.colors.graySemi}; // #666f73;
    }
  }

  ul {
    li {
      padding-left: 24px;
      position: relative;

      &:before {
        content: '';
        display: block;
        background-color: ${(props) => props.theme.colors.red};
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 17px;

        ${media.largeScreen`
          top: 15px;
        `}
        ${media.smallScreen`
          top: 12px;
        `}
      }
    }
  }

  ol {
    list-style: inside;
    list-style-type: decimal;
  }

  &.text-center * {
    text-align: center;
  }

  &.text-right * {
    text-align: right;
  }
`;
