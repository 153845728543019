import React from 'react';

import { ReactSVG } from 'react-svg';

import { Overlay } from '@components/organisms/Overlay';

import leftImg from 'images/chevron_left.svg';
import rightImg from 'images/chevron_right.svg';
import closeImg from 'images/x.svg';

import * as S from './styles';
import { IProps } from './types';

export const ImageGallery: React.FC<IProps> = ({
  hide,
  show,
  target,
  images,
  index,
}: IProps) => {
  const [imageIndex, setImageIndex] = React.useState<number>(index);

  React.useEffect(() => {
    if (imageIndex >= images.length) {
      setImageIndex(0);
    }
  }, [images]);

  const nextSlide = () => {
    if (images && imageIndex + 1 < images.length) {
      setImageIndex(imageIndex + 1);
    } else {
      setImageIndex(0);
    }
  };
  const prevSlide = () => {
    if (images && imageIndex <= 0) {
      setImageIndex(images.length - 1);
    } else {
      setImageIndex(imageIndex - 1);
    }
  };

  return (
    <Overlay position="fit" show={show} hide={hide} target={target}>
      <S.Modal>
        <S.Header divider>
          <S.Counter>
            {imageIndex + 1}/{images && images.length}
          </S.Counter>
          <S.CloseImg>
            <ReactSVG src={closeImg} onClick={hide} />
          </S.CloseImg>
        </S.Header>
        <S.Preview>
          {images && images.length > 0 && imageIndex < images.length && (
            <img
              src={images ? images[imageIndex].url : ''}
              alt={images ? images[imageIndex].alt : ''}
            />
          )}
        </S.Preview>
        <S.Navigation>
          <S.Previous type="button" onClick={prevSlide}>
            <ReactSVG src={leftImg} />
          </S.Previous>
          <S.Next type="button" onClick={nextSlide}>
            <ReactSVG src={rightImg} />
          </S.Next>
        </S.Navigation>
      </S.Modal>
    </Overlay>
  );
};
