import { REVIEW_AVATARS } from '@temp/core/config';

import Image from 'next/future/image';

import { useIntl } from 'react-intl';

import NiceModal from '@ebay/nice-modal-react';
import { ReviewItemModal } from '@components/overlays/ReviewItem';

import imgPlaceholder from 'images/review-placeholder.png';
import { commonMessages } from '../../../../intl';

import { RatingStars } from './RatingStars';

import * as S from './styles';
import { IReviewProps } from './types';

const DESC_LENGHT_CONST = 100;

export const ReviewItem: React.FC<IReviewProps> = ({ item, showGallery }) => {
  const intl = useIntl();

  return (
    <S.Wrapper className="ReviewItem">
      <S.ImagesList>
        {item?.images.length > 0 && (
          <S.ImageGalleryButton
            type="button"
            onClick={() => showGallery(item.images, 0)}
          >
            <Image
              src={item.images[0].url}
              alt={item.images[0].alt}
              width="300"
              height="400"
            />
            {item.images.length > 1 && (
              <S.ImageCount>{item.images.length - 1}+</S.ImageCount>
            )}
          </S.ImageGalleryButton>
        )}
        {item?.images.length === 0 && (
          <S.ImagePlaceholder>
            <Image
              src={imgPlaceholder.src}
              alt="placeholder"
              width="300"
              height="400"
            />
          </S.ImagePlaceholder>
        )}
      </S.ImagesList>
      <S.Column>
        <S.Header>
          <S.Rating>
            <RatingStars rating={item.rating} />
          </S.Rating>
          <S.Title>{item.title}</S.Title>
          <S.Description>
            {item?.description.length > DESC_LENGHT_CONST ? (
              <>
                <div>{item.description.slice(0, DESC_LENGHT_CONST)}...</div>
                <S.ReadMoreButton
                  type="button"
                  onClick={() => {
                    NiceModal.show(ReviewItemModal, {
                      item,
                      showGallery,
                    });
                  }}
                >
                  {intl.formatMessage(commonMessages.ReadMore)}
                </S.ReadMoreButton>
              </>
            ) : (
              <span>{item.description}</span>
            )}
          </S.Description>
        </S.Header>
        <S.Extra>
          <S.Avatar>
            <Image
              src={REVIEW_AVATARS.get(item?.avatar)}
              width="56"
              height="56"
              alt="avatar"
              style={{ maxWidth: '100%', height: '100%' }}
            />
          </S.Avatar>
          <S.Author>
            <S.AuthorName>{item?.fullName}</S.AuthorName>
            <S.AuthorDate>{intl.formatDate(item.publicationDate)}</S.AuthorDate>
          </S.Author>
        </S.Extra>
      </S.Column>
    </S.Wrapper>
  );
};
