import { REVIEW_AVATARS } from '@temp/core/config';

import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Header, CloseImg } from '@components/molecules/CardHeader/styles';
import { Overlay } from '@components/organisms/Overlay';

import { RatingStars } from '@components/molecules/ReviewItem/RatingStars';
import closeImg from 'images/x.svg';

import * as S from './styles';
import { IProps } from './types';

const ReviewItem: React.FC<IProps> = ({ item, showGallery }) => {
  const intl = useIntl();
  const modal = useModal();
  const gallerySliced = item.images.slice(0, 4);

  return (
    <Overlay
      position="fit"
      show={modal.visible}
      hide={modal.remove}
      transparent
    >
      <S.Modal>
        <Header divider={false}>
          <CloseImg>
            <ReactSVG src={closeImg} onClick={modal.remove} />
          </CloseImg>
        </Header>
        <S.Content>
          <S.Gallery>
            {gallerySliced &&
              gallerySliced.map((img, i, gallery) => (
                <S.ImageButton
                  type="button"
                  key={i}
                  onClick={() => showGallery(gallery, i)}
                >
                  <img src={img.url} alt={img.alt} />
                  {gallerySliced.length > 1 && (
                    <S.ImageCount>{item.images.length - 1}+</S.ImageCount>
                  )}
                </S.ImageButton>
              ))}
          </S.Gallery>
          <S.Rate>
            <RatingStars rating={item.rating} />
          </S.Rate>
          <S.Title>{item.title}</S.Title>
          <S.Description>{item.description}</S.Description>
          <S.Extra>
            <S.Avatar>
              <img src={REVIEW_AVATARS.get(item.avatar)} alt="avatar" />
            </S.Avatar>
            <S.Author>
              <S.AuthorName>{item.fullName}</S.AuthorName>
              <S.AuthorDate>
                {intl.formatDate(item.publicationDate)}
              </S.AuthorDate>
            </S.Author>
          </S.Extra>
        </S.Content>
      </S.Modal>
    </Overlay>
  );
};

export default NiceModal.create(ReviewItem);
